import configJson from   '../Config.json';

const CommonObj = {
    Endpoints: {
        "GetPage": configJson.baseUrl + "/api/page/get",
        "AddContactUs": configJson.baseUrl + "/api/contactus/create",
        "PresentationContactUs": configJson.baseUrl + "/api/contactus/presentation-page",
        "GetResource": configJson.baseUrl +"/api/resource/list",
        "ContentListEndpoint": configJson.baseUrl +"/api/page/GetContentList"
    },
    ContentType: {
        Banners: 1,
        Blogs: 2,
        Carousels: 3,
        FAQS: 4,
        Icons: 5,
        Infographics: 6,
        Branches: 7,
        Panels: 8,
        Testimonials: 9,
        Thumbnails: 10,
        CaseStudies: 11,
        Articles: 12,
        Videos: 13,
        Media: 14
    },
    PageNameList:{
        Home:'index',
        sample:'sample',
        AboutUs:'About-Us',
        People:'People',
        FeedBack:'Feedback',
        Resources:'Resources',
        ContactUs:'Contact-Us',
        Experience:'Experience',
        GBSContentEditorial:'GBS-Content-Editorial',
        GBSOverview:'GBS-Overview',
        GBSBrandingCreative:'GBS-Branding-Creative',
        GBSCreativeAutomation:'GBS-CreativeAutomation',
        GBSOnsiteInhouseAgency:'GBS-OnsiteInhouseAgency',
        GBSCreativeProduction:'GBS-CreativeProduction',
        GBSCreativeProductionPresentationServices:'GBS-CreativeProduction-PresentationServices',
        GBSCreativeProductionPremediaPrepress:'GBS-CreativeProduction-Premedia-Prepress',
        GBSCreativeProductionAccessibilityRemediation:'GBS-CreativeProduction-AccessibilityRemediation',
        GBSCreativeProduction3DSolutions:'GBS-CreativeProduction-3DSolutions',
        GBSMarketingAutomationInsights:'GBS-Marketing-Automation-Insights',
        SWSOverview:'SWS-Overview',
        SWSOperationsTransformation:'SWS-OperationsTransformation',
        SWSOperationTransformationRBA:'SWS-OperationsTransformation-RetirementBenefitsAdministration',
        SWSOperationTransformationIS:'SWS-OperationsTransformation-InsuranceServices',
        LifeInsurance:'LifeInsurance',
        GeneralInsurance:'GeneralInsurance',
        SWSOperationTransformationREIT:'SWS-OT-REIT-Accounting-Services',
        SWSOperationTransformationUtilities:'SWS-OT-Utilities',
        SWSProductivitySolutions:'SWS-ProductivitySolutions',
        SWSSharedServicesTransformation:'SWS-SharedServicesTransformation',
        TISOverview:'TIS-Overview',
        TISDataServices:'TIS-DataServices',
        TISInnovationAsAService:'TIS-Innovation-As-A-Service',
        TISIntelligentAutomationSolutions:'TIS-IntelligentAutomationSolutions',
        SWS_OT_IS:'smart-work-solutions-operations-transformation-insurance-services-life-insurance',
        TISITServices:'TIS-ITServices',
        Index_duplicate : "index-duplicate",
        ImmersiveAndInteractive: "Immersive-and-Interactive",
        ContactusResponse:"Contactus-Response",
        Thankyou:"Thankyou",
        Blogs:"Blogs",
        CaseStudies:"CaseStudies"
    },
    ResourceKeyDefaults : {
        "FEEDBACK" : "FeedBack",
        "ABOUTUS-LINK" : "About Us",
        "HOME-LINK" : "Home",
        "CAREERS-LINK":'Careers',
        "CLIENT_SS_TSM-TITLE": "Client Success Stories & Testimonials",
        "FAQS-TITLE":"FAQs",
        "BLOGS-TITLE":"Blogs",
        "RESOURCES-LINK":"Resources",
        "PPL-LINK":"People",
        "PPL-ICON-TITLE":"Diverse Teams. One RRD.",
        "PPL-PANEL-GROW":"Grow Together. Succeed Together.",
        "PPL-PANEL-FIND-BEAT":"Find Your Beat.",
        "PPL_ON_OUR_MANTEL_PIECE": <><div className="container__title"><h2 className="align-center">On Our Mantelpiece.</h2></div><p className="align-center  ch60 m_auto mt-20">Our commitment to ensuring an employee-centric, growth-oriented work environment has won us several accolades.</p></>,
        "CU-LINK":"Contact Us",
        "EXP_CTR-LINK":"Experience Center",
        "GBS-DROPDOWN-TITLE":"Global Brand Solutions",
        "GBS-OV-LINK":"Overview",
        "GBS-CONT_EDIT-LINK":"Content & Editorial",
        "GBS-DSG_CREAT-LINK":"Design & Creative",
        "GBS-PAS-LINK":"Creative Production",
        "GBS-PAS-PS-LINK":"Presentation Services",
        "GBS-PAS-AR-LINK":"Accessibility Remediation",
        "GBS-MI-LINK":"MarTech Implementation",
        "GBS-OS_IH_AGCY-LINK":"Onsite In-house Agency",
        "SWS-DROPDOWN-TITLE":"Smart Work Solutions",
        "SWS-OV-LINK":"Overview",
        "SWS-PAS-LINK":"Productivity Solutions",
        "SWS-OT-LINK":"Operations Transformation",
        "SWS-RBA-LINK":"Retirement Benefits Administration",
        "SWS-SST-LINK":"Shared Services Transformation",
        "TIS-DROPDOWN-TITLE":"Technology & Innovation Solutions",
        "TECH_INNOV-OV-LINK":"Overview",
        "TECH_INNOV-INNOV_SERV-LINK":"Innovation-as-a-Service",
        "TECH_INNOV-IAS-LINK": "Intelligent Automation Solutions",
        "TECH_INNOV-DS-LINK":"Data Services",
        "TECH_INNOV-IT_SERVICES-LINK":"IT Services",
        "BLOGS-PAGE-HEADING":"BLOGS",
        "CASESTUDIES-PAGE-HEADING":"CASE STUDIES",
        "CU-LOCATIONS-TITLE":"Locations",
        "CU-POPUP-TITLE":"CONTACT US",
        "CU-TEXTBOX1-CAPTION":"Name",
        "CU-TEXTBOX2-CAPTION":"Organization",
        "CU-TEXTBOX3-CAPTION":"Email Address",
        "CU-TEXTBOX4-CAPTION":"Phone Number",
        "CU-TEXTAREA-CAPTION":"Tell us about your request...",
        "CU-TITLE":"Always available to assist you!",
        "CU-BUTTON":"CONTACT US",
        "CU-BUTTON-CAMEL":"Contact us",
        "RESOURCES-CS-TITLE":"Case Studies",
        "GBS-CONT_EDIT-ICONS-TITLE":"Here is what we can do for you:",
        "GBS-DSG_CREAT-ICONS-TITLE":"Here’s what our alchemists deliver:",
        "GBS-OS_IH_AGCY-ICONS-TITLE":"Here are some of the services we offer",
        "GBS-MI-ICON-TITLE":"This is how we make a difference to your campaigns:",
        "GBS-PAS-ICONS-TITLE":"Our digital factory can deliver these services at scale:",
        "GBS-PAS-THUMBNAILS-TITLE":"Our Service Offerings",
        "SWS-OT-ICONS-TITLE":"Here are some of the industries we support",
        "SWS-OT-THUMBNAILS-TITLE":"Our Service Offerings",
        "SWS-PAS-ICONS-TITLE":"<h2 className='align-center center-content mt0 mb0'>The CATCH ecosystem</h2><p className='align-center'>Optishore™, a global delivery network of more than 2000 professionals, leverages:</p>",
        "SWS-SST-ICONS-TITLE":"Utilize our shared services transformation toolkit:",
        "GBS-PAS-PS-ICONS1-TITLE":"Service Portfolio",
        "GBS-PAS-PS-ICON-1":"",
        "GBS-PAS-PS-ICON-1-TITLE": <div><p className='align-center small-title'>Presentations tailored to your needs</p><h2 className='align-center center-content mt0 mb0'>Fixed prices without surprises</h2><p className='align-center sub-title'>No hourly billing and extra fees, just cost determined on a per-sildes basis</p></div>,
        "GBS-PAS-PS-THUMBNAILS-TITLE":"<h2 classname='align-center center-content mt0 mb0'>Customer Outcomes</h2><p classname='align-center'>Our flexible and scalable presentation services leverage visual designs, iconography, storytelling, and creative direction to:</p>",
        "LIFE-INSURANCE-THUMB-TITLE":<><h2 className='align-center center-content mt0 mb0'>Customer Outcomes</h2><p className='align-center life-insurance-p'>We partner with you to plan and execute complex business and operational changes that can help</p></>,
        "GEN-INSURANCE-THUMB-TITLE":<><h2 className='align-center center-content mt0 mb0'>Customer Outcomes</h2><p className='align-center life-insurance-p'>We partner with you to plan and execute complex business and operational changes that can help</p></>,
        "PANEL-EXPERTSPEAK-TITLE":"Expertspeak",
        "GBS-PAS-AR-ICONS1-TITLE":"<h2 classname='align-center center-content mt0 mb0'>Service Portfolio</h2><p classname='align-center'>Leveraging the right mix of solutions, innovation, and resources, RRD RemediAd can accelerate your move towards full accessibility.</p>",
        "GBS-PRESENTATION-BUTTON":<a href="#" className="btn btn__secondary btn_portfolio"><div className="btn__text" rel="noopener noreferrer">VIEW PORTFOLIO</div><div className="btn__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 69"><g><circle cx="34.25" cy="34.25" r="33.5" fill="none" strokeLinecap="round"></circle><line x1="20.59" y1="34.63" x2="48.03" y2="34.63" fill="none" strokeLinecap="round"></line><path d="M35.7,21.75h0A31.25,31.25,0,0,0,46.64,33.41l2,1.22-1.74,1A29,29,0,0,0,36.27,47h0" fill="none" strokeLinecap="round"></path></g></svg></div></a>,
        "GBS-PRESENTATION-OFFER-BUTTON-1":<div className='d-flex'><h2 className='mt0 mb0'>Offer Starts from $9.99</h2><div className='ps_center'><button className='custom-trigger form-trigger-1'><a className='btn btn__secondary form-trigger-btn'  rel="noopener noreferrer"><div className='btn__text'>Try our Presentation Services</div><div className='btn__icon'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 69 69'><g><circle cx='34.25' cy='34.25' r='33.5' fill='none' strokeLinecap='round'></circle><line x1='20.59' y1='34.63' x2='48.03' y2='34.63' fill='none' strokeLinecap='round'></line><path d='M35.7,21.75h0A31.25,31.25,0,0,0,46.64,33.41l2,1.22-1.74,1A29,29,0,0,0,36.27,47h0' fill='none' strokeLinecap='round'></path></g></svg></div></a></button></div></div>,
        "GBS-PRESENTATION-OFFER-BUTTON-2":<><h2 className='mt0 mb0'>Offer Starts from $9.99</h2><div className='ps_center'><button className='custom-trigger form-trigger-2'><a className='btn btn__secondary'  rel="noopener noreferrer"><div className='btn__text'>Try our Presentation Services</div><div className='btn__icon'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 69 69'><g><circle cx='34.25' cy='34.25' r='33.5' fill='none' strokeLinecap='round'></circle><line x1='20.59' y1='34.63' x2='48.03' y2='34.63' fill='none' strokeLinecap='round'></line><path d='M35.7,21.75h0A31.25,31.25,0,0,0,46.64,33.41l2,1.22-1.74,1A29,29,0,0,0,36.27,47h0' fill='none' strokeLinecap='round' ></path></g></svg></div></a></button></div></>,
        "GBS-PAS-AR-THUMBNAIL-BOTTOM_CONTENT":"*Sec. 508 of the US Federal Law, ** Web Content Accessibility Guidelines",
        "GBS-PAS-AR-THUMBNAIL-TITLE":"<h2 classname='align-center center-content mt0 mb0'>Customer Outcomes</h2><p classname='align-center'>With RRD RemediAd, you can:</p>",
        "GBS-CP-PP-THUMBNAIL-TITLE":<h2 className='align-center center-content mt0 mb0'>Customer Outcomes</h2>,
        "SWS-RBA-ICONS-TITLE":"<h2 classname='align-center center-content mt0 mb0'>Service Offerings</h2><p classname='align-center'>Streamline workflows, protect your assets, and enhance your overall customer experience with our technology-infused services.</p>",
        "SWS-RBA-THUMBNAILS-TITLE":" <h2 classname='align-center center-content mt0 mb0'>Customer Outcomes</h2><p classname='align-center'>For retirement benefit administrators looking to deliver outstanding customer experiences, RRD GO Creative™ offers end-to-end support and a suite of customized solutions. Our flexible, data-powered solutions address the needs of your demanding customer base by ensuring always-on support and timely, accurate output at scale.</p><p classname='align-clearInterval'> RRD Retirement Support Services leverages micro automation, process re-engineering, and human oversight to enable:</p>",
        "SWS-OT-IS-LINK":"Insurance Services",
        "SWS-OT-IS-ICON1-TITLE":"<h2 className='align-center center-content mt0 mb0'>Service Portfolio</h2>",
        "SWS-OT-REIT-LINK":"RRD Real Estate Accounting Services",
        "SWS-OT-REIT-ICON1-TITLE":"Service Portfolio",
        "SWS-OT-REIT-ICON1-HEADER1":"Managed Services",
        "SWS-OT-REIT-ICON1-HEADER2":"Financial Reporting & Insights",
        "SWS-OT-REIT-ICON1-HEADER3":"Merger & Acquisition",
        "GBS-CP-PP-HEADER-1":"Layout and Composition",
        "GBS-CP-PP-HEADER-2":"Prepress services",
        "GBS-CP-PP-HEADER-3":"",
        "SWS-OT-REIT-ICON2-TITLE":" <h2 className='align-center center-content mt0 mb0'>Customer Outcomes</h2> <div className='align-center center_align mt0'><div className='ch60 m_auto'>Our future-proof and scalable solutions, proven methodologies, and dedicated teams can support your growth strategy and help you:</div></div>",
        "TECH_INNOV-DS-ICONS-TITLE":"Our game-changing data services include:",
        "TECH_INNOV-INNOV_SERV-ICONS-TITLE":"Your customizable Loft package includes all of these:",
        "TECH_INNOV-IAS-ICONS-TITLE":"Our bots can deliver what you need:",
        "TECH_INNOV-IAS-ICONS-TITLE-HEADER1":"Backend Automation",
        "TECH_INNOV-IAS-ICONS-TITLE-HEADER2":"Frontend Automation",
        "FOOTER1-SUB-TITLE":"Our Solutions",
        "FOOTER1-LOGO-DESC":"We help you make Customer Experience your competitive differentiator by reimagining customer interactions, and how your customers connect with your brand.",
        "TECH_INNOV-IT_SERVICES-ICONS-TITLE":"Accelerate digital transformation with our on-demand services:",
        "GBS-PAS-3DSOL-LINK":"3D Solutions",
        "GBS-PAS-3DSOL-PAGE-ICONS1-TITLE":"<h2 class='align-center center-content mt0 mb0'>Service Portfolio</h2><div class='align-center ch60 m_auto mt-20'>From high-end 3D services like product design, VFX, and architectural renderings to volume-based 2D to 3D conversions for the e-commerce market, we have the capacity and capability to scale and deliver outstanding quality work in:</div>",
        "GBS-PAS-3DSOL-ICONS2-TITLE":"<h2 class='align-center center-content mt0 mb0'>Customer Outcomes</h2><div class='align-center center_align mt0'><div class=''>Enter a multidimensional gateway to offer your products and services with RRD GO Creative’s™ 3D solutions that deliver new, intuitive, and interactive customer experiences. By ensuring expedited development, shorter time-to-market, reduced costs, and a simple supply chain, our 3D solutions can power any business. Our end-to-end 3D platform entirely transform your business by providing:</div></div>",
        "SWS-OT-UTIL-LINK":"Utilities",
        "SWS-OT-UTIL-ICON1-TITLE":"Service Portfolio",
        "SWS-OT-UTIL-ICON2-TITLE":"<h2 className='align-center center-content mt0 mb0'>Customer Outcomes</h2><div className='align-center center_align mt0'><div className='ch60 m_auto'>We deliver operational and marketing solutions at scale to help you:</div></div>",
        "GBS-PAS-PREMEDIA-PRESS-LINK":"Premedia and Prepress",
        "SWS-OT-REIT-PANEL-BTN":<button className='form-small-trigger form-trigger'><a className='reach-us-at form-trigger-btn' rel="noopener noreferrer"><span><h3 className='color-white'>Contact us</h3></span><span><img src='/assets/img/SVG/arrow_white.svg' alt='arrow icon'/></span></a></button>,
        "GBS-CP-PS-PANEL-BTN":<button className='form-small-trigger form-trigger'><a className='reach-us-at form-trigger-btn' rel="noopener noreferrer"><span><h3 className='color-white'>Contact us</h3></span><span><img src='/assets/img/SVG/arrow_white.svg' alt='arrow icon'/></span></a></button>,
        "GBS-CP-PP-PANEL-BTN":"",
        "LIFE-INSURANCE-ICONS-TITLE":<h3 className="align-center center-content mt0 mb0"> The areas in which RRD GO Creative™ offers strategic services are:</h3>,
        "LIFE-INSURANCE-ICONS":"",
        "GEN-INSURANCE-ICONS-TITLE":<h3 className="align-center center-content mt0 mb0"> The areas in which RRD GO Creative™ offers strategic services for General Insurance are:</h3>,
        "GEN-INSURANCE-ICONS":"",
        "GBS-MI-THUMBNAIL-TEXT-ONE":"PD360 Solution Benefits",
        "ImmersiveAndInteractive-THUMBNAILS-TITLE": "Industries we serve",
        "IMMERSIVE-AND-INTERACTIVE": <a  rel="noopener noreferrer" href={configJson.ImmersivePageUrl} >Immersive-and-Interactive</a>,
        "RESOURCE_POLICY_UPLOAD":"<div class='bg-white resource_document'> <section class='container'> <h4 class='align-center'>Corporate Social Responsibility Policy</h4> <a href='https://gorrd-dev-gocms-s3-data.s3.amazonaws.com/ContentDocument/180794/RRD_GO_CSR_Policy.pdf' target='_blank' class='m_auto mt-30 reach-us-at btnBorder'><span><p class='color-primary'>View Document</p></span><span><div class='btn__icon color-primary'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 69 69'><g><circle cx='34.25' cy='34.25' r='33.5' fill='none' stroke-linecap='round'></circle><line x1='20.59' y1='34.63' x2='48.03' y2='34.63' fill='none' stroke-linecap='round></line><path d='M35.7,21.75h0A31.25,31.25,0,0,0,46.64,33.41l2,1.22-1.74,1A29,29,0,0,0,36.27,47h0' fill='none' stroke-linecap='round'></path></g></svg></div></span></a>  </section> </div> <style> .resource_document .btnBorder{     border: 1px solid #A50050; } .resource_document .btn__icon{     stroke: #A50050; } </style>",
        "THANKYOU_CONTENT":"",
    },
    SortType: {
        Ascending: "Ascending",
        Decending: "Descending",
        Last_3_Months: "Last 3 Months",
        Last_6_Months: "Last 6 Months",
    },
    FilterType: {
        Last_3_Months: "Last 3 Months",
        Last_6_Months: "Last 6 Months",
        All:"All"
    }
};
export default CommonObj;