import React from 'react';
import StaticResourceText from '../Format/static-resource-text';
import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Footer = (props) => {
    return (
        <div className='footer'>
            <footer className="footer__wrapper footer container-mt">
                <section className="container">
                    <div className="footer__container">
                        <div className="widget" data-grid="gc">
                            <div className="widget__inner">
                                <h2 className="visually-hidden">RRD GO Creative</h2>
                                <img className="footer__logo" src="/assets/img/SVG/rrd_logo_inverse.svg" alt="RRD Logo" />
                                <div className='footer content' async><StaticResourceText resources={props.resources} reskey={'FOOTER1-LOGO-DESC'}></StaticResourceText></div>
                                <ul className="social">
                                    <li><a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/RRDGOCreative"><img src="/assets/img/SVG/Facebook.svg" alt="Icon of Facebook" /></a></li>
                                    <li><a target='_blank' rel="noopener noreferrer" href="https://twitter.com/rrdonnelley?t=P_d3yyuXN6_BT2JWa42G-g&s=03"><img src="/assets/img/SVG/Twitter.svg" alt="Icon of Twitter" /></a></li>
                                    <li><a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/c/RRDGOCreative"><img src="/assets/img/SVG/Youtube.svg" alt="Icon of Youtube" /></a></li>
                                    <li><a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/rrdgocreative_showcase/"><img src="/assets/img/SVG/Instagram.svg" alt="Icon of Instagram" /></a></li>
                                    <li><a target='_blank' rel="noopener noreferrer" href="https://www.linkedin.com/company/rrd-globaloutsourcingsolutions/"><img src="/assets/img/SVG/Linkedin.svg" alt="Icon of Linkedin" /></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="widget" data-grid="solution">
                            <h3 className="widget-title" async><StaticResourceText resources={props.resources} reskey={'FOOTER1-SUB-TITLE'}></StaticResourceText></h3>
                            <ul className="list-unstyled">
                            <li><Link to={"/global-brand-solutions"}><StaticResourceText resources={props.resources} reskey={'GBS-DROPDOWN-TITLE'}></StaticResourceText> </Link></li>
                            <li><NavLink to={"/smart-work-solutions"}><StaticResourceText resources={props.resources} reskey={'SWS-DROPDOWN-TITLE'}></StaticResourceText> </NavLink></li>
                            <li><NavLink to={"/technology-innovation-solutions"}><StaticResourceText resources={props.resources} reskey={'TIS-DROPDOWN-TITLE'}></StaticResourceText> </NavLink></li>
                            </ul>
                        </div>

                        <div className="widget" data-grid="resource">
                        
                            <h3 className="widget-title"><NavLink to={"/about-us"}><StaticResourceText resources={props.resources} reskey={'ABOUTUS-LINK'}></StaticResourceText> </NavLink></h3>
                            <h3 className="widget-title"><NavLink to={"/people"}><StaticResourceText resources={props.resources} reskey={'PPL-LINK'}></StaticResourceText> </NavLink></h3>
                            <h3 className="widget-title"><NavLink to={"/resources"}><StaticResourceText resources={props.resources} reskey={'RESOURCES-LINK'}></StaticResourceText> </NavLink></h3>
                            <h3 className="widget-title"><a target='_blank' rel="noopener noreferrer" href={"https://rrd.zohorecruit.in/jobs/Careers"}><StaticResourceText resources={props.resources} reskey={'CAREERS-LINK'}></StaticResourceText></a></h3>
                        </div>

                        <div className="widget" data-grid="main">
                            <h3 className="widget-title"><NavLink to={"/contact-us"}><StaticResourceText resources={props.resources} reskey={'CU-LINK'}></StaticResourceText> </NavLink></h3>
                            <h3 className="widget-title"><NavLink to={"/"}><StaticResourceText resources={props.resources} reskey={'HOME-LINK'}></StaticResourceText> </NavLink></h3>
                        </div>
                    </div>
                </section>
                <div className="copyright">
                    <div className="container">

                        <div className="copyright__links">
                            <div className="copyright__logo">
                            <a target="_blank" href="https://www.rrd.com/">
                                <img className="disclogo lazyloaded" data-src="/assets/img/rrd-disclaimer-logo.png" alt="RR Donnelley Logo" src="/assets/img/rrd-disclaimer-logo.png" />
                            </a>
                            </div>

                            <div className="copyright__text footer-disclaimer disclaimer">
                                <ul className="menu__container">
                                    <li className="menu"><a rel="noopener noreferrer" href="https://www.rrd.com/cookie-policy">Cookie Policy</a></li>
                                    <li className="menu"><a id="ot-sdk-btn" className="ot-sdk-show-settings" data-ignore-geolocation="true">Cookie Settings</a></li>
                                    <li className="menu"><a rel="noopener noreferrer" href="https://www.rrd.com/privacy-policy">Privacy</a></li>
                                    <li className="menu"><a rel="noopener noreferrer" href="https://www.rrd.com/terms-of-use" aria-label="Footer terms">Terms</a></li>
                                    <li className="menu"><a rel="noopener noreferrer" href="https://www.rrd.com/wcag">Accessibility</a></li>
                                </ul>
                                <p>© 1998 - 2022 R.R. Donnelley &amp; Sons Company, all rights reserved.</p>
                                <p>RR DONNELLEY, RRD, RRD (Stylized) and COMMUNICATIONS ENABLED are trademarks or registered trademarks of R. R. Donnelley &amp; Sons Company. All other trademarks are the property of R. R. Donnelley or their respective owners.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}
export default Footer